import Picture6 from "../images/Picture6.jpg";
import DeployingBoom from "../images/DeployingBoom.png";
import NextToBoom from "../images/NextToBoom.jpg";
import WinchVideo from "../videos/Winch.MOV";
import BoomVideo from "../videos/BoomFullyDeployed.MOV";
import Slogan from "./Slogan";
import { useMediaQuery } from "@material-ui/core";

export default function Product() {
    let isCompact = useMediaQuery("(max-width:750px)");
    return (
        <>
            <Slogan />
            <div>
                <h1>Product</h1>
                <img
                    src={Picture6}
                    width={isCompact ? "100%" : "35%"}
                    style={{ float: isCompact ? "none" : "right", marginLeft: "2%", marginBottom: "2%" }}
                />
                <p>
                    Onboard Response has developed a safe, affordable and proactive method of containing oil spills
                    before they have a chance to spread. What starts off as a containment issue can quickly spread and
                    turn into an environmental disaster. Rapid and safe containment protects the environment,
                    stakeholders and a company’s reputation.
                </p>
                <p>
                    Our boom reel can be modified to handle different sizes and specifications of boom. We can design a
                    product that fits your needs. Harbor boom with a small reel to Offshore Boom and a large reel.
                </p>
                <img
                    src={NextToBoom}
                    width={isCompact ? "100%" : "45%"}
                    style={{ float: isCompact ? "none" : "left", marginRight: "2%", marginBottom: "2%" }}
                />
                <p>
                    When using the compressed air from a vessel’s own systems, there is no need for additional diesel-
                    powered hydraulic units to power the reel or gasoline powered blowers to fill the sections. This
                    makes Onboard Response a “Green” option.
                </p>
                <p>
                    We want to work with your company to improve your standing with your pollution insurance underwriter
                    by showing your proactive approach to oil spill response and help boost your ESG profile with a
                    proactive and “Green” alternative to traditional containment options.
                </p>

                <div
                    style={{
                        display: "flex",
                        flexDirection: isCompact ? "column" : "row",
                        justifyContent: "space-between",
                    }}
                >
                    {!isCompact ? (
                        <div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "47.5%",
                                    height: 0,
                                    paddingBottom: "26.5%",
                                }}
                            >
                                <iframe
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    src={`https://www.youtube.com/embed/tu_odW_8zsc`}
                                    autoplay={true}
                                    frameBorder="0"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            </div>

                            <div
                                style={{
                                    position: "relative",
                                    width: "47.5%",
                                    height: 0,
                                    paddingBottom: "26.5%",
                                }}
                            >
                                <iframe
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    src={`https://www.youtube.com/embed/rAq6n2UTFzw`}
                                    autoplay={true}
                                    frameBorder="0"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    height: 0,
                                    paddingBottom: "55%",
                                    marginBottom: "5%",
                                }}
                            >
                                <iframe
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    src={`https://www.youtube.com/embed/tu_odW_8zsc`}
                                    autoplay={true}
                                    frameBorder="0"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    height: 0,
                                    paddingBottom: "55%",
                                    marginBottom: "5%",
                                }}
                            >
                                <iframe
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    src={`https://www.youtube.com/embed/rAq6n2UTFzw`}
                                    autoplay={true}
                                    frameBorder="0"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
