import Slogan from "./Slogan";
export default function Team() {
    return (
        <>
            <Slogan />
            <div>
                <h1>Team</h1>
                <h2 style={{ textAlign: "left" }}>Gary Culbertson, CEO and Co-Founder</h2>
                <p>
                    Experienced finance executive with over 26 years of experience, working with middle-market and small
                    public and private energy service, maritime, shipbuilding and energy businesses. He has served as a
                    consultant on business and banking transactions for small to mid-sized companies and 24 years as
                    middle market banker for energy and maritime companies. He has practical experience in all aspects
                    of corporate banking, including: marketing, analyzing, modeling, underwriting, structuring,
                    negotiation of terms, structuring alternative debt strategies, leading club sized syndications and
                    workout. Mr. Culbertson holds a BBA from Loyola University, with a double major of Finance and
                    International Business.
                </p>
                <h2 style={{ textAlign: "left", marginTop: "7%" }}>Captain Michael Miller, Co-Founder and Inventor</h2>
                <p>
                    A professionally licensed mariner whose career includes navigating ocean going ships entering and
                    exiting the Mississippi River from the Gulf of Mexico. During his 30-year career, he has witnessed
                    the environmental impact of oil spills and the problematic race against the clock in terms of
                    mobilization, deployment, and clean up. The constant challenge is not having the right resources to
                    combat the spill and the length of time it takes to mobilize a response. Recognizing that solutions
                    must be immediate, Captain Miller created a boom system that addresses the void he saw within his
                    industry. His knowledge of vessels, crew operations, and marine transportation logistics served as
                    the vision for the product.
                </p>
                <h2 style={{ textAlign: "left", marginTop: "7%" }}>Richard Cryar, Advisor</h2>
                <p>
                    Principal and Managing Member of FMReed. Mr. Reed has 38 years of finance, mergers, acquisitions,
                    divestitures in the oil field, marine transportation, shipbuilding and REITs. He has participated in
                    industries as a principal, financial advisor, CPA and executive management, including Managing
                    Member of an investment/merchant banking firm and General Partner of a private equity fund. Mr.
                    Cryar has served as the President of Leevac Marine, Inc., President of Leevac Shipyards and served
                    on the Board of Hornbeck Offshore.
                </p>
                <h2 style={{ textAlign: "left", marginTop: "7%" }}>Sean Griffin, Consulting Engineer</h2>
                <p>
                    Mr. Griffin is President and founder of Proteus Technologies, LLC, an engineering consulting firm
                    that provides technology consulting in sensors and instrumentation development with a strong
                    emphasis in oceanographic instrumentation, acoustics and SONAR development. Mr. Griffin has 30+
                    years designing/developing systems, sensors and instrumentation for the marine environment and has
                    been instrumental in developing the project from design to the prototypes.
                </p>
            </div>
        </>
    );
}
