import logo from "./logo.svg";
import "./App.css";
import Header from "./Header.js";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/Home.js";
import Product from "./pages/Product.js";
import About from "./pages/About.js";
import Solution from "./pages/Solution";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import ReactGA from "react-ga";
import { useEffect } from "react";

function App() {
    const TRACKING_ID = "G-SXTTWCJBVD";
    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
    }, []);

    return (
        <div className="App" style={{ minHeight: "90vh" }}>
            <Router>
                <Header />
                <Switch>
                    <Route path="/product">
                        <Product />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/solution">
                        <Solution />
                    </Route>
                    <Route path="/team">
                        <Team />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
