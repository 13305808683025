import React, { useEffect, useState } from "react";
import BoomDeployed from "../images/BoomDeployed.jpg";
import DeployingBoom from "../images/DeployingBoom.png";
import Picture4 from "../images/Picture4.png";
import DroneDeploy from "../images/dronedeploy.jpg";
import LookingAtBoom from "../images/LookingAtBoom.jpg";
import BoomOnBoat from "../images/BoomOnBoat.jpg";
import BoomVideo from "../videos/BoomFullyDeployed.MOV";
import { useMediaQuery } from "@material-ui/core";
import Slogan from "./Slogan";

export default function Home() {
    let isCompact = useMediaQuery("(max-width:750px)");
    let [isPhone, setIsPhone] = useState(false);
    const check = () => {
        if (/mobile/i.test(navigator.userAgent)) {
            setIsPhone(true);
        }
    };

    useEffect(() => {
        check();
    }, []);

    return (
        <div>
            <Slogan />
            <br />
            <div
                style={{
                    display: "flex",
                    flexDirection: isCompact || isPhone ? "column" : "row",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                }}
            >
                <img src={BoomOnBoat} width={isCompact || isPhone ? "80%" : "30%"} />
                <img src={DroneDeploy} width={isCompact || isPhone ? "80%" : "30%"} />
                <img src={LookingAtBoom} width={isCompact || isPhone ? "80%" : "30%"} />
            </div>
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: 0,
                    paddingBottom: "56.25%",
                }}
            >
                <iframe
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                    src={`https://www.youtube.com/embed/rAq6n2UTFzw`}
                    autoplay={true}
                    frameBorder="0"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        </div>
    );
}
