import React from "react";
import { useMediaQuery } from "@material-ui/core";
import Slogan from "./Slogan";

export default function Solution() {
    let isCompact = useMediaQuery("(max-width:750px)");
    return (
        <>
            <Slogan />
            <div>
                <div
                    style={{
                        backgroundColor: "#D5D5D5",
                        padding: "3% 5% 3% 3%",
                        margin: "3% 0%",
                        borderRadius: "75px",
                    }}
                >
                    <h1 style={{ textAlign: isCompact ? "center" : "left", paddingLeft: "2%" }}>
                        Problem: Current oil response is outdated
                    </h1>
                    <ul>
                        <li>Approximately 90% of oil loss occurs within the first 4 hours after a spill.</li>
                        <li>
                            However, it can take an OSRO, on average, 2-10 hours to arrive on site and even longer in
                            remote areas.
                        </li>
                        <li>
                            During this critical time after a spill, oil spreads rapidly across the surface of the
                            water.
                        </li>
                        <li>A crew might be able to secure the cause of the leak, but they can’t contain the spill.</li>
                        <li>
                            Small spill kits onboard are designed to contain an on-deck spill, but there aren’t
                            sufficient containment assets onboard vessels to safely address a small to medium size
                            on-water spill.
                        </li>
                        <li>
                            Why not? Well traditional forms of oil containment, if placed onboard would take up too much
                            space and take 5- 6 crewmembers to operate and deploy. This would take away crew that could
                            be used to secure the safety of the vessel. Most inflatable booms would also need ancillary
                            diesel-powered hydraulic equipment to power the reel and some would need a separate
                            gasoline- powered blower to inflate the boom.
                        </li>
                        <li>
                            As the crew waits for the OSRO to arrive, with nothing to contain the spill, the oil
                            continues to spread and the potential environmental damage and risk of fines/penalties
                            increases.
                        </li>
                    </ul>
                </div>
                <div
                    style={{
                        backgroundColor: "#00385E",
                        padding: "3% 5% 3% 3%",
                        margin: "3% 0%",
                        color: "#D5D5D5",
                        borderRadius: "75px",
                    }}
                >
                    <h1 style={{ textAlign: isCompact ? "center" : "left", paddingLeft: "2%" }}>
                        Solution:{" "}
                        <span style={{ color: "#ED680E" }}>
                            <i>Onboard Response</i>
                        </span>
                    </h1>
                    <ul>
                        <li>
                            Onboard Response’s product is a proactive response using new technology to quickly contain a
                            spill in those critical hours after an incident.
                        </li>
                        <li>In testing, 500ft of offshore boom was deployed and inflated in 25 minutes.</li>
                        <li>
                            Our unit is designed to be housed onboard vessels and is easily deployed by only 2 people.
                        </li>
                        <li>
                            It runs on compressed air, so it is a green alternative. No ancillary diesel hydraulic power
                            units or gas blowers are needed.
                        </li>
                        <li>The unit has a small footprint and doesn’t need much extra space to operate.</li>
                        <li>
                            In the event of a spill, the responsible party can be subject to fines, penalties,
                            regulatory scrutiny, environmental damages, damages to marine life, costs of shutting down
                            trade and damages to their reputation and bottom line.
                        </li>
                        <li>
                            Our system provides a captain with a proactive option to, when safe, deploy boom and contain
                            the spill, while continuing to wait for the OSRO.
                        </li>
                        <li>
                            Onboard Response’s rapid containment can help reduce damages and keep a small spill from
                            becoming a famous spill.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
