import { Button } from "@material-ui/core";
import { useState } from "react";
import ContactForm from "./ContactForm";
import Slogan from "./Slogan";

export default function Contact() {
    let [name, setName] = useState("");
    let [email, setEmail] = useState("");
    let [message, setMessage] = useState("");

    const onNameChange = (val) => {
        setName(val);
    };

    const onEmailChange = (val) => {
        setEmail(val);
    };

    const onMessageChange = (val) => {
        setMessage(val);
    };

    const consthandleSubmit = (event) => {};

    return (
        <>
            <Slogan />
            <div>
                <h1>Contact</h1>
                <p>
                    <strong style={{ fontSize: "24px" }}>Onboard Response</strong>
                    <br />
                    gculbertson&#64;OBResponse.com <br />
                    Office: 504-861-1777 <br />
                    New Orleans, LA 70125
                </p>
            </div>
        </>
    );
}
