import { useMediaQuery, Button, Drawer } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OBResponseLogo from "./images/OBResponseLogo.png";
import MenuIcon from "@material-ui/icons/Menu";

export default function Header() {
    let isCompact = useMediaQuery("(min-width:992px)");
    let compressHeader = useMediaQuery("(max-width:650px");
    let [drawerOpen, setDrawerOpen] = useState(false);
    let [isPhone, setIsPhone] = useState(false);
    const check = () => {
        if (/mobile/i.test(navigator.userAgent)) {
            setIsPhone(true);
        }
    };

    useEffect(() => {
        check();
    }, []);

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <div>
            {compressHeader || isPhone ? (
                <header className="App-header-compress">
                    <div style={{ width: "30%" }}>
                        <Link to="/" className="nav-link">
                            <img src={OBResponseLogo} width="100%" />
                        </Link>
                    </div>
                    <Button onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </Button>
                    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                        <div
                            style={{
                                padding: "10px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                gap: "20px",
                                alignItems: "center",
                                width: window.innerWidth * 0.35,
                            }}
                        >
                            <Link to="/" className="nav-link">
                                <Button>Home</Button>
                            </Link>
                            <Link to="/about" className="nav-link">
                                <Button>About</Button>
                            </Link>
                            <Link to="/product" className="nav-link">
                                <Button>Product</Button>
                            </Link>
                            <Link to="/solution" className="nav-link">
                                <Button>Solution</Button>
                            </Link>
                            <Link to="/team" className="nav-link">
                                <Button>Team</Button>
                            </Link>
                            <Link to="/contact" className="nav-link">
                                <Button>Contact</Button>
                            </Link>
                        </div>
                    </Drawer>
                </header>
            ) : (
                <header className="App-header">
                    <Link to="/" className="nav-link">
                        <Button>Home</Button>
                    </Link>
                    <Link to="/about" className="nav-link">
                        <Button>About</Button>
                    </Link>
                    <Link to="/product" className="nav-link">
                        <Button>Product</Button>
                    </Link>
                    <Link to="/" className="nav-link">
                        <img src={OBResponseLogo} width={isCompact ? 300 : 100} />
                    </Link>
                    <Link to="/solution" className="nav-link">
                        <Button>Solution</Button>
                    </Link>
                    <Link to="/team" className="nav-link">
                        <Button>Team</Button>
                    </Link>
                    <Link to="/contact" className="nav-link">
                        <Button>Contact</Button>
                    </Link>
                </header>
            )}
        </div>
    );
}
