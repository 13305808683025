import Slogan from "./Slogan";
export default function About() {
    return (
        <>
            <Slogan />
            <div style={{ maxWidth: "900px", margin: "auto" }}>
                <h1>About</h1>
                <p>
                    Onboard Response provides a safe and effective way of rapidly controlling marine vessel oil spills.
                    We have a patented containment boom reel unit that is designed to be housed onboard a vessel and
                    deployed in the event of a spill. The boom is deployed to limit the spread of the oil until the Oil
                    Spill Recovery Organization’s (OSRO’s) assets can arrive. Similar to a portable fire extinguisher,
                    but for an oil spill. The reel unit, powered by the vessel’s own compressed air system and inflates
                    the boom as it exits the reel. Because it uses compressed air, there is no need for ancillary gas-
                    or diesel-powered blowers or power units, making this a green option.
                </p>
            </div>
        </>
    );
}
