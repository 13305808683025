import React from "react";
import { useMediaQuery } from "@material-ui/core";

export default function Slogan() {
    let isCompact = useMediaQuery("(max-width:750px)");
    return (
        <>
            <hr />
            <h3>
                <i>Keeps a small spill from becoming famous</i>
            </h3>
            <hr />
        </>
    );
}
